<template>
  <v-container fluid>
    <common-error
      v-if="errors.isErrorMou"
      @fetchData="$emit('fetchMouDetail')"
    />
    <section v-else-if="loaders.isLoadingMou">
      <v-row v-for="skeleton in 10" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <v-form
      v-else
      ref="form"
      lazy-validation
      disabled
    >
      <h4>{{$_strings.mou.COOPERATION_INFORMATION}}</h4>
      <v-row class="mt-5">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="companyId" class=" ma-0" for="selectCompany">{{$_strings.mou.COMPANY_NAME_TABLE_HEADER_LABEL}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-select
            placeholder="Nama Perusahaan"
            dense
            outlined
            class="caption"
            clearable
            searchName="companyName"
            item-value="id"
            item-text="name"
            disabled
            v-model="form.companyId"
            :items="itemsCompany"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="companyAbbreviation" class="body-2 ma-0" for="textInitial">{{$_strings.mou.ALIAS_INITIAL_TABLE_HEADER_LABEL}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="textInitial"
            disabled
            class="body-2"
            v-model="form.companyAbbreviation"
            required
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="contractNo" class="body-2 ma-0" for="noContract">{{$_strings.mou.NO_CONTRACT}}
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="noContract"
            class="body-2"
            v-model="form.contractNo"
            placeholder="Autogenerate"
            required
            disabled
            dense
            outlined
            filled
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="mouNo" class="body-2 ma-0" for="mouNumber">{{$_strings.mou.MOU_NUMBER_TABLE_HEADER_LABEL}}
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="mouNumber"
            class="body-2"
            v-model="form.mouNo"
            placeholder="Autogenerate"
            required
            disabled
            dense
            outlined
            filled
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="parentMouNo" class="body-2 ma-0" for="mouParentNumber">{{$_strings.mou.MOU_PARENT_NUMBER_TABLE_HEADER_LABEL}}
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="mouParentNumber"
            class="body-2"
            :value="form.parentMouNo || '-'"
            required
            disabled
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_periodicCooperation" class="body-2 ma-0" for="periodicCooperation">{{$_strings.mou.PERIODIC_COOPERATION}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="auto" sm="3" class="pt-0">
          <v-menu
            ref="menuFromDate"
            v-model="menuFromDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="periodicCooperation"
                dense
                outlined
                :value="form.mouPeriodStart ? dateFormat(form.mouPeriodStart) : ''"
                class="body-2"
                placeholder="Dari tanggal"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.mouPeriodStart"
              no-title
              :min="dayjs().format('YYYY-MM-DD')"
              :max="form.mouPeriodEnd ? dayjs(form.mouPeriodEnd).format('YYYY-MM-DD') : ''"
              scrollable
              @change="menuFromDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="auto" class="pt-0">
          <span class="body-2 ma-0">{{$_strings.mou.TO}} <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </span>
        </v-col>
        <v-col cols="auto" sm="3" class="pt-0">
          <v-menu
            ref="menuToDate"
            v-model="menuToDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                :value="form.mouPeriodEnd ? dateFormat(form.mouPeriodEnd) : ''"
                class="body-2"
                placeholder="Sampai tanggal"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.mouPeriodEnd"
              no-title
              :min="form.mouPeriodStart ? dayjs(form.mouPeriodStart).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')"
              scrollable
              locale="id"
              @change="menuToDate = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_mouPeriodStartDoc" class="body-2 ma-0">{{$_strings.mou.PERIODE_START_DOC}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="auto" sm="3" class="pt-0">
          <v-menu
            ref="menuMouPeriodStartDoc"
            v-model="menuMouPeriodStartDoc"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                :value="form.mouPeriodStartDoc ? dateFormat(form.mouPeriodStartDoc) : ''"
                class="body-2"
                placeholder="Dari tanggal"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.mouPeriodStartDoc"
              no-title
              scrollable
              locale="id"
              @change="menuMouPeriodStartDoc = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="auto" class="pt-0">
          <span class="body-2 ma-0">{{$_strings.mou.TO}} <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </span>
        </v-col>
        <v-col cols="auto" sm="3" class="pt-0">
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                :value="form.mouPeriodEnd ? dateFormat(form.mouPeriodEnd) : ''"
                disabled
                class="body-2"
                placeholder="Sampai tanggal"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              disabled
              v-model="form.mouPeriodEnd"
              no-title
              scrollable
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="isAutoExtend" class="body-2 ma-0" for="autoExtendMonth">{{$_strings.mou.AUTO_EXTENDOR}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="auto" class="mt-0 pt-0">
          <v-radio-group
            class="mt-0 pa-0"
            row
            v-model="form.isAutoExtend"
          >
            <v-radio
              :key="1"
              :value="true"
              label="Ya"
            ></v-radio>
            <v-radio
              :key="2"
              :value="false"
              label="Tidak"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            v-if="form.isAutoExtend"
            class="body-2"
            :value="form.autoExtendMonth ? `Bulan ${form.autoExtendMonth}` : '-'"
            required
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="termOfPayment" class="body-2 ma-0" for="termOfPayment">{{$_strings.mou.TERM_OF_PAYMENT}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            class="body-2"
            :value="form.termOfPayment || '-'"
            required
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="paymentMethod" class="body-2 ma-0" for="paymentMethod">
            {{$_strings.mou.PAYMENT_METHOD}}
          </label>
        </v-col>
        <v-col cols="auto" class="mt-0 pt-0">
          <v-radio-group
            class="mt-0 pa-0"
            row
            v-model="radioButtonPaymentMethod"
          >
            <v-radio
              :value="1"
              label="Ya"
            ></v-radio>
            <v-radio
              :value="2"
              label="Tidak"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            v-if="radioButtonPaymentMethod === 1"
            id="paymentMethod"
            class="body-2"
            v-model="form.paymentMethod"
            dense
            disabled
            outlined>
          </v-text-field>
          <v-text-field
            v-else
            id="paymentMethod"
            class="body-2"
            v-model="form.paymentMethod"
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_paymentPic" class="body-2 ma-0" for="paymentPic">{{$_strings.mou.PAYMENT_PIC}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-0 pt-0">
          <v-select
            v-model="form.paymentPicSalutation"
            outlined
            class="body-2"
            dense
            :items="picTitleItems"
          ></v-select>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="paymentPic"
            v-model="form.paymentPic"
            class="body-2"
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="paymentPicMsisdn" class="body-2 ma-0" for="paymentPicMsisdn">{{$_strings.mou.PAYMENT_PIC_NUMBER_PHONE}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <phone-number-format
            id="paymentPicMsisdn"
            v-model="form.paymentPicMsisdn"
            dense
            class="body-2"
            outlined
            disabled
            :required="false"
          >
          </phone-number-format>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_operationalPic" class="body-2 ma-0" for="paymentOperational">{{$_strings.mou.OPERATIONAL_PIC}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-0 pt-0">
          <v-select
            v-model="form.operationalPicSalutation"
            outlined
            class="body-2"
            dense
            :items="picTitleItems"
          ></v-select>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            v-model="form.operationalPic"
            id="paymentOperational"
            class="body-2"
            dense
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="operationalPicMsisdn" class="body-2 ma-0" for="operationalPicMsisdn">No Telepon PIC Operasional
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <phone-number-format
            id="operationalPicMsisdn"
            v-model="form.operationalPicMsisdn"
            dense
            class="body-2"
            outlined
            disabled
            :required="false"
          >
          </phone-number-format>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="operationalPicJobTitle" class="body-2 ma-0" for="operationalPicJobTitle">Posisi PIC Operasional
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="operationalPicJobTitle"
            dense
            class="body-2"
            outlined
            v-model="form.operationalPicJobTitle"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="isInsurance" class="body-2 ma-0" for="clientInsurance">
            {{$_strings.mou.CLIENTS_USING_INSURANCE}}
          </label>
        </v-col>
        <v-col cols="auto" class="mt-0 pt-0">
          <v-radio-group
            id="clientInsurance"
            class="mt-0 pa-0"
            row
            v-model="form.isInsurance"
          >
            <v-radio
              :value="true"
              label="Ya"
            ></v-radio>
            <v-radio
              :value="false"
              label="Tidak"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="isAuditedByKap" class="body-2 ma-0" for="clientInsurance">{{$_strings.mou.FINANCIAL_STATEMENTS_KAP}}
          </label>
        </v-col>
        <v-col cols="auto" class="mt-0 pt-0">
          <v-radio-group
            id="clientInsurance"
            class="mt-0 pa-0"
            row
            v-model="form.isAuditedByKap"
          >
            <v-radio
              :value="true"
              label="Ya"
            ></v-radio>
            <v-radio
              :value="false"
              label="Tidak"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row  class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="averageShipment" class="body-2 ma-0" for="averageShipment">Rata-rata Nilai Barang Per Pengiriman
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            prefix="Rp"
            id="averageShipment"
            dense
            outlined
            :value="formatAsCurrency(form.averageShipment)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="totalAsset" class="body-2 ma-0" for="totalAsset">{{$_strings.mou.TOTAL_ASSET}}</label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="totalAsset"
            prefix="Rp"
            :value="formatAsCurrency(form.totalAsset)"
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="ventureCapital" class="body-2 ma-0" for="ventureCapital">{{$_strings.mou.STARTUP_CAPITAL}}</label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            prefix="Rp"
            id="ventureCapital"
            dense
            outlined
            :value="formatAsCurrency(form.ventureCapital)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="shareholder" class="body-2 ma-0" for="shareHolder">
            {{$_strings.mou.SHAREHOLDERS}}
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="shareHolder"
            v-model="form.shareholder"
            dense
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="bankName" class="body-2 ma-0" for="bankName">{{$_strings.mou.BANK_NAME}}
            <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="bankName"
            v-model="form.bankName"
            dense
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="bankBranch" class="body-2 ma-0" for="bankBranch">{{$_strings.mou.DISTRIBUTARY}}
            <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="bankBranch"
            v-model="form.bankBranch"
            dense
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_bankAccountName" class="body-2 ma-0" for="accountOwner">{{$_strings.mou.ACCOUNT_OWNER}}
            <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-0 pt-0">
          <v-select
            v-model="form.bankAccountNameSalutation"
            outlined
            class="body-2"
            dense
            :items="accountOwnerItems"
          ></v-select>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="bankAccountName"
            v-model="form.bankAccountName"
            class="body-2"
            dense
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="bankAccountNo" class="body-2 ma-0" for="accountNumber">{{$_strings.mou.ACCOUNT_NUMBER}}
            <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="accountNumber"
            v-mask="{'mask': '9', 'repeat': 30, allowMinus: false, rightAlign: false}"
            v-model="form.bankAccountNo"
            dense
            outlined
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_typeOfService" class="body-2 ma-0" for="typeOfService">Service Types
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-checkbox
            class="ma-0"
            label="Intergrated Service"
            color="primary"
            v-model="form.isIntegratedService"
            hide-details
          ></v-checkbox>
          <v-checkbox
            label="Transportation"
            color="primary"
            v-model="form.isTransportation"
            hide-details
          ></v-checkbox>
          <div class="pl-8" v-if="form.isTransportation">
            <v-checkbox
              v-model="form.isMultiPick"
              label="Multipick"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.isMultiDrop"
              label="Multidrop"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.isBackhauling"
              label="Backhauling"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.isMultiModa"
              label="Multimoda"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.isMultiShipper"
              label="Multishipper"
              color="primary"
              hide-details
            ></v-checkbox>
          </div>
          <v-checkbox
            label="Warehousing"
            color="primary"
            v-model="form.isWarehousing"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            v-if="!isApprovalPage"
            color="blue"
            class="mr-4 white--text"
            @click="download"
            :loading="downloading"
          >
            <v-icon>mdi-download</v-icon>{{$_strings.common.DOWNLOAD}}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4"
            @click="navigateToFormLocationAndTransporter"
          >
            {{$_strings.common.NEXT}}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <DialogChangeHistory
      :form="form"
      :formMouPrevVersion="formMouPrevVersion"
      ref="dialogChangeHistory"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '@/helper/commonHelpers';
import DialogChangeHistory from '../Dialog/ChangeHistoryCooperationInformation/index.vue';

export default {
  name: 'cooperation-information',
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    loaders: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
    formMouPrevVersion: {
      type: Object,
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
    itemsCompany: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DialogChangeHistory,
  },
  mounted() {
    this.handleBtnFromShowChangeHistory();
  },
  data() {
    return {
      menuFromDate: false,
      menuToDate: false,
      menuMouPeriodStartDoc: false,
      picTitleItems: ['Bpk', 'Ibu'],
      accountOwnerItems: ['Bpk', 'Ibu', 'PT', 'CV'],
      downloading: false,
    };
  },
  watch: {
    formMouPrevVersion: {
      handler() {
        this.handleBtnFromShowChangeHistory();
      },
      deep: true,
    },
  },
  computed: {
    radioButtonPaymentMethod: {
      get() {
        const { paymentMethod } = this.form;
        if (paymentMethod === 'TRANSFER') return 1;
        return 2;
      },
      set() {},
    },
    fromPath() {
      const [,, path] = this.$route.path.split('/');
      return path;
    },
    isApprovalPage() {
      const pageApproval = ['approval-inbox', 'approval-process', 'complete-approval'];
      return pageApproval.includes(this.fromPath);
    },
  },
  methods: {
    dayjs,
    dateFormat,
    createBtnChangeHistory(type) {
      const div = document.createElement('div');
      const btn = document.createElement('button');
      btn.onclick = (event) => this.showDialogChangeHistory(event, type);
      btn.setAttribute('class', 'caption red--text pa-0 ma-0 v-btn v-btn--text theme--light v-size--default');
      const span = document.createElement('span');
      span.setAttribute('class', 'v-btn__content');
      span.textContent = 'Ada Perubahan';
      const i = document.createElement('i');
      i.setAttribute('aria-hidden', 'true');
      i.setAttribute('class', 'v-icon notranslate ml-4 mdi mdi-information-outline theme--light red--text');
      i.setAttribute('style', 'font-size: 15px;');
      btn.appendChild(span);
      btn.appendChild(i);
      div.appendChild(btn);
      return div;
    },
    handleBtnFromShowChangeHistory() {
      if (!this.isPageInbox) return;
      // check _periodicCooperation
      if (dateFormat(this.form.mouPeriodStart)
        !== dateFormat(this.formMouPrevVersion.mouPeriodStart)
        || dateFormat(this.form.mouPeriodEnd)
        !== dateFormat(this.formMouPrevVersion.mouPeriodEnd)) {
        const btn = this.createBtnChangeHistory('_periodicCooperation');
        this.$refs._periodicCooperation.insertBefore(btn, this.$refs._periodicCooperation.nextSibling);
      }
      // check _mouPeriodStartDoc
      if (dateFormat(this.form.mouPeriodStartDoc)
        !== dateFormat(this.formMouPrevVersion.mouPeriodStartDoc)
        || dateFormat(this.form.mouPeriodEnd)
        !== dateFormat(this.formMouPrevVersion.mouPeriodEnd)) {
        const btn = this.createBtnChangeHistory('_mouPeriodStartDoc');
        this.$refs._mouPeriodStartDoc.insertBefore(btn, this.$refs._mouPeriodStartDoc.nextSibling);
      }
      // check _paymentPic
      if (this.form.paymentPicSalutation
        !== this.formMouPrevVersion.paymentPicSalutation
        || this.form.paymentPic
        !== this.formMouPrevVersion.paymentPic) {
        const btn = this.createBtnChangeHistory('_paymentPic');
        this.$refs._paymentPic.insertBefore(btn, this.$refs._paymentPic.nextSibling);
      }
      // check _operationalPic
      if (this.form.operationalPicSalutation
        !== this.formMouPrevVersion.operationalPicSalutation
        || this.form.operationalPic
        !== this.formMouPrevVersion.operationalPic) {
        const btn = this.createBtnChangeHistory('_operationalPic');
        this.$refs._operationalPic.insertBefore(btn, this.$refs._operationalPic.nextSibling);
      }
      // check _bankAccountName
      if (this.form.bankAccountNameSalutation
        !== this.formMouPrevVersion.bankAccountNameSalutation
        || this.form.bankAccountName
        !== this.formMouPrevVersion.bankAccountName) {
        const btn = this.createBtnChangeHistory('_bankAccountName');
        this.$refs._bankAccountName.insertBefore(btn, this.$refs._bankAccountName.nextSibling);
      }
      // check _typeOfService
      const obj1 = {
        isIntegratedService: this.form.isIntegratedService,
        isTransportation: this.form.isTransportation,
        isMultiPick: this.form.isMultiPick,
        isMultiDrop: this.form.isMultiDrop,
        isBackhauling: this.form.isBackhauling,
        isMultiModa: this.form.isMultiModa,
        isMultiShipper: this.form.isMultiShipper,
        isWarehousing: this.form.isWarehousing,
      };
      const obj2 = {
        isIntegratedService: this.formMouPrevVersion.isIntegratedService,
        isTransportation: this.formMouPrevVersion.isTransportation,
        isMultiPick: this.formMouPrevVersion.isMultiPick,
        isMultiDrop: this.formMouPrevVersion.isMultiDrop,
        isBackhauling: this.formMouPrevVersion.isBackhauling,
        isMultiModa: this.formMouPrevVersion.isMultiModa,
        isMultiShipper: this.formMouPrevVersion.isMultiShipper,
        isWarehousing: this.formMouPrevVersion.isWarehousing,
      };
      if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        const btn = this.createBtnChangeHistory('_typeOfService');
        this.$refs._typeOfService.insertBefore(btn, this.$refs._typeOfService.nextSibling);
      }

      Object.keys(this.formMouPrevVersion).forEach((key) => {
        if (this.formMouPrevVersion[key] !== this.form[key]) {
          if (this.$refs[key]) {
            const btn = this.createBtnChangeHistory(key);
            this.$refs[key].insertBefore(btn, this.$refs[key].nextSibling);
          }
        }
      });
    },
    showDialogChangeHistory(event, type) {
      this.$refs.dialogChangeHistory.type = type;
      this.$refs.dialogChangeHistory.dialog = true;
      event.preventDefault();
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    navigateToFormLocationAndTransporter() {
      const step = 1; // => LOCATION AND TRANSPORTER
      this.$emit('changeStep', step);
    },
    async download() {
      try {
        const { id: mouId } = this.form;
        this.downloading = true;
        const result = await this.$_services.mou.donwloadMou(mouId);
        this.$_services.uploads.downloadFile(result.data);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>
