<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-card :loading="isLoading" class="pa-2">
      <v-card-title class="caption">
        <h2 class="pl-8">{{$_strings.mou.LIMIT}}</h2>
      </v-card-title>
      <v-card-title class="caption">
        <v-row class="pl-8">
          <v-col class="pb-0">
            <v-row class="pb-0">
              <v-col class="pb-0" cols="12" sm="4">
                <h3 class="grey--text">{{$_strings.mou.NUMBER_OF_VEHICLES}}</h3>
              </v-col>
              <v-col cols="12" sm="4">
                <h3 class="grey--text">{{item.transportTypeName || item.typeTransporter}}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" cols="12" sm="4">
                <h3 class="grey--text">{{$_strings.mou.NUMBER_OF_UNIT}}</h3>
              </v-col>
              <v-col cols="12" sm="4">
                <h3 class="grey--text">{{item.unitLimit}}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" cols="12" sm="4">
                <h3 class="grey--text">{{$_strings.mou.INTERVAL}}</h3>
              </v-col>
              <v-col class="pb-0" cols="12" sm="4">
                <h3 class="grey--text">{{item.interval}}</h3>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row v-if="!form.length && !isLoading" class="mt-2">
          <v-col class="d-flex justify-center">
            <div><h2>{{$_strings.mou.NO_DATA}}</h2></div>
          </v-col>
        </v-row>
        <v-form ref="form">
          <v-data-table
            v-if="form.length"
            :headers="headerLicensePlate"
            :items="form"
            hide-default-footer
            :items-per-page="1000"
            class="elevation-0 pa-4 header_license_plate"
            item-key="companyTransportId"
          >
            <template v-slot:[`item.licensePlate`]="{item}">
              <v-text-field
                dense
                outlined
                hide-details
                disabled
                v-model="item.licensePlate"
              />
            </template>
            <template v-slot:[`item.price`]="{}">
              {{formatAsCurrency(item.price)}}
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions
        class="d-flex justify-center"
      >
        <v-row justify="center">
          <v-col cols="4">
            <v-btn
              block
              color="red"
              small
              class="white--text mr-4"
              @click="dialog = false"
            >
              {{$_strings.common.CLOSE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  props: {
    mouId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      headerLicensePlate: [
        {
          text: this.$_strings.mou.LICENSE_PLATE,
          value: 'licensePlate',
          sortable: false,
          class: 'black--text tranparent license_plate',
        },
        {
          text: this.$_strings.mou.RITASE_OF_LIMIT,
          value: 'limits',
          sortable: false,
          class: 'black--text tranparent',
        },
        {
          text: this.$_strings.mou.PRICE_OF_INTERVAL,
          value: 'price',
          sortable: false,
          class: 'black--text tranparent',
        },
      ],
      mouTransportLimit: [],
      item: {},
      totalItems: 0,
      form: [],
      isLoadingListLicensePlate: [],
      isLoadingGetListLicensePlate: false,
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        source = CancelToken.source();
        const { id, unitLimit } = this.item;
        if (id && unitLimit) {
          this.fetchListTransportTypeLimit();
        }
      }
      if (!newVal) {
        this.mouTransportLimit = [];
        this.form = [];
        source.cancel('CANCELED_BY_THE_USER');
      }
    },
  },
  methods: {
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    async fetchListTransportTypeLimit() {
      const { id, unitLimit } = this.item;
      const { mouId } = this;
      if (!id || !unitLimit) return;
      const filters = {
        page: 0,
        size: unitLimit,
      };
      try {
        this.isLoading = true;
        const result = await this.$_services.mou.getListTransportTypeLimit(mouId, id, filters, source);
        this.form = result?.data?.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep > .v-data-table__wrapper > table > .v-data-table-header > tr > th:nth-child(1)::after {
  content: '*';
  color: red;
  font-size: 12px;
}
</style>
