<template>
  <v-container fluid class="pa-0">
    <v-row class="mt-2 mb-4">
      <v-col cols="12">
        <v-data-table
          :key="dialog"
          :loading="isLoading"
          item-key="id"
          calculate-widths
          class="elevation-1"
          :server-items-length="totalData"
          :options.sync="pagination"
          :headers="headers"
          :items="items"
          :item-class="itemRowBackground"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
        >
          <template v-slot:[`header.priceKilo`]="{ header }">
            <div class="d-flex align-center">
              <span class="text-center">{{header.text}}</span>
              <span>
                <v-checkbox
                  color="white"
                  class="d-inline"
                  v-model="form.isPriceKilo"
                  hide-details
                  disabled
                />
              </span>
            </div>
          </template>
          <template v-slot:[`header.priceVolume`]="{ header }">
            <div class="d-flex align-center">
              <span class="text-center">{{header.text}}</span>
              <span>
                <v-checkbox
                  color="white"
                  class="d-inline"
                  v-model="form.isPriceVolume"
                  hide-details
                  disabled
                />
              </span>
            </div>
          </template>
          <template v-slot:[`header.priceCarton`]="{ header }">
            <div class="d-flex align-center">
              <span class="text-center">{{header.text}}</span>
              <span>
                <v-checkbox
                  color="white"
                  class="d-inline"
                  v-model="form.isPriceCarton"
                  hide-details
                  disabled
                />
              </span>
            </div>
          </template>
          <template v-slot:[`item.origin`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.originLocationName}}
                </span>
              </template>
              <span>{{$_strings.order.ORIGIN}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.destination`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.destinationLocationName }}
                </span>
              </template>
              <span>{{$_strings.order.DESTINATION}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.priceKilo`]={item}>
            <p class="text-center">{{formatAsCurrency(item.priceKilo)}}</p>
          </template>
          <template v-slot:[`item.priceVolume`]={item}>
            <p class="text-center">{{formatAsCurrency(item.priceVolume)}}</p>
          </template>
          <template v-slot:[`item.priceCarton`]={item}>
            <p class="text-center">{{formatAsCurrency(item.priceCarton)}}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'list-lcl-table-history',
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    fetchData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'origin',
          class: 'white--text primary text-capitalize text-center',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          class: 'white--text primary text-capitalize text-center',
          value: 'destination',
          sortable: false,
        },
        {
          text: this.$_strings.order.ETA,
          class: 'white--text primary text-capitalize text-center',
          value: 'etaDays',
          sortable: false,
        },
        {
          text: 'Tonase(kg) dalam (Rp)',
          class: 'white--text primary text-capitalize text-center',
          value: 'priceKilo',
          sortable: false,
        },
        {
          text: 'Kubikasi(m3) dalam (Rp)',
          class: 'white--text primary text-capitalize text-center',
          value: 'priceVolume',
          sortable: false,
        },
        {
          text: 'Karton(ktn) dalam (Rp)',
          class: 'white--text primary text-capitalize text-center',
          value: 'priceCarton',
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      items: [],
      totalData: 0,
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.pagination = {
          page: 1,
          itemsPerPage: 10,
        };
        this.totalData = 0;
        this.items = [];
      }
    },
    pagination: {
      handler() {
        if (this.dialog) this.getData();
      },
      deep: true,
    },
  },
  methods: {
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return `Rp. ${formatNumber.format(value) || 0}`;
    },
    async getData() {
      try {
        this.isLoading = true;
        const result = await this.fetchData(this.pagination);
        this.items = result.data.contents.map((d, index) => ({
          ...d,
          index,
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    itemRowBackground(item) {
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
  },
};
</script>
