<template>
  <v-container fluid>
    <v-tabs-items v-model="step">
      <v-tab-item>
        <cooperation-information
          :isPageInbox="isPageInbox"
          :form="form"
          :itemsCompany="itemsCompany"
          :loaders="loaders"
          :errors="errors"
          :formMouPrevVersion="formMouPrevVersion"
          @fetchMouDetail="handleData"
          @changeStep="changeStep"
        />
      </v-tab-item>
      <v-tab-item>
        <location-and-transport-types
          :isPageInbox="isPageInbox"
          :mouId="mouId"
          :mouLocations="mouLocations"
          :mouTransportTypes="mouTransportTypes"
          :loaders="loaders"
          :errors="errors"
          :totalDataLocation="totalDataLocation"
          :totalDataTransportTypes="totalDataTransportTypes"
          @fetchMouLocation="fetchMouLocation"
          @fetchMouTransportType="fetchMouTransportType"
          @changeStep="changeStep"
        />
      </v-tab-item>
      <v-tab-item>
        <price-list-setting
          :isPageInbox="isPageInbox"
          :form="form"
          :mouId="mouId"
          :checkboxServiceType="checkboxServiceType"
          @changeStep="changeStep"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import dayjs from 'dayjs';
import LocationAndTransportTypes from './LocationAndTransportTypes/index.vue';
import CooperationInformation from './CooperationInformation.vue';
import PriceListSetting from './PriceSetting/PriceListSetting.vue';

export default {
  name: 'mou-detail',
  components: {
    CooperationInformation,
    LocationAndTransportTypes,
    PriceListSetting,
  },
  created() {
    const { data } = this.$route.params;
    if (data && this.defWorkflowId) {
      this.setFormData(data.data);
    } else if (data) {
      this.setFormData(data);
    } else {
      this.handleData();
    }
  },
  data() {
    return {
      step: 0,
      form: {
        id: null,
        activeStatus: 'INACTIVE',
        workflowId: null,
        companyId: null,
        companyName: '',
        companyAbbreviation: '',
        companyType: '',
        serviceTypes: '',
        mouNo: '',
        mouType: '',
        contractNo: null,
        parentMouId: null,
        parentMouNo: null,
        effectiveStartDate: '',
        effectiveEndDate: '',
        mouPeriodStart: '',
        mouPeriodEnd: '',
        isAutoExtend: false,
        autoExtendMonth: 0,
        termOfPayment: '',
        isP2pLending: false,
        paymentMethod: '',
        paymentPicSalutation: 'Bpk',
        paymentPic: '',
        paymentPicMsisdn: '',
        operationalPicSalutation: 'Bpk',
        operationalPic: '',
        operationalPicJobTitle: '',
        operationalPicMsisdn: '',
        isInsurance: false,
        isAuditedByKap: false,
        averageShipment: 0,
        totalAsset: 0,
        ventureCapital: 0,
        shareholder: '',
        bankName: '',
        bankBranch: '',
        bankAccountNameSalutation: 'Bpk',
        bankAccountName: '',
        bankAccountNo: '',
        isIntegratedService: false,
        isTransportation: false,
        isMultiPick: false,
        isMultiDrop: false,
        isBackhauling: false,
        isMultiModa: false,
        isMultiShipper: false,
        isWarehousing: false,
        otherServices: null,
        isPriceFixed: false,
        isPriceKilo: false,
        isPriceVolume: false,
        isPriceCarton: false,
        isPriceOnCall: false,
        approvalStatus: '',
        mouLocations: [],
        mouTransportTypes: [],
        mouTransportTypeLimit: [],
        p2pLending: [],
        createdBy: '',
        createdAt: null,
        updatedBy: '',
        updatedAt: null,
        mouDocument: null,
        mouPeriodStartDoc: null,
      },
      isShowMouExtendButton: false,
      // GLOBAL ITEMS
      itemsCompany: [],
      itemsFclServiceType: [],
      itemsFclBackhaulingServiceType: [],
      itemsLclServiceType: [],
      // MOU DATA
      mouLocations: [],
      mouTransportTypes: [],
      mouTransportTypeLimit: [],
      mouLclRoute: [],
      mouFclRoute: [],
      mouFclBackhaulingRoute: [],
      // ITEMS PAGINATION
      pageFilters: {
        fclServiceType: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        fclBackhaulingServiceType: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        lclServiceType: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
      },
      // model
      checkboxServiceType: {
        isFCL: false,
        isBackhauling: false,
        isLCL: false,
      },
      // loaders
      loaders: {
        isLoadingMou: false,
        isLoadingMouLocation: false,
        isLoadingMouTransportTypes: false,
        isLoadingTransportTypeLimit: false,
        isLoadingMouTransportTypeLimit: false,
        isLoadingLclMouRoute: false,
        isLoadingFclMouRoute: false,
        isLoadingFclBackhaulingMouRoute: false,
      },
      // errors
      errors: {
        isErrorMou: false,
        isErrorMouLocation: false,
        isErrorMouTransportTypes: false,
        isErrorTransportTypeLimit: false,
        isErrorLclMouRoute: false,
        isErrorFclMouRoute: false,
        isErrorFclBackhaulingMouRoute: false,
      },
      totalDataLocation: 0,
      totalDataTransportTypes: 0,
      totalTransportTypeLimitData: 0,
      totalLclRouteData: 0,
      totalFclRouteData: 0,
      totalFclBackhaulingRouteData: 0,
      defaultFilters: {
        page: 1,
        itemsPerPage: 10,
      },
      filterMouRoute: {
        fcl: {
          originId: '',
          destinationId: '',
        },
        fclBackhauling: {
          originId: '',
          destinationId: '',
        },
        lcl: {
          originId: '',
          destinationId: '',
        },
      },
    };
  },
  computed: {
    formMouPrevVersion() {
      const _distinctions = this.form.distinctions || {};
      return {
        ...this.form,
        ..._distinctions,
      };
    },
    fromPath() {
      // approval-inbox, approval-process, complete-approval
      const [,, path] = this.$route.path.split('/');
      return path;
    },
    isPageInbox() {
      return this.fromPath === 'approval-inbox';
    },
    mouId() {
      return +this.form.id || 0;
    },
    defWorkflowId() {
      const { defWorkflowId } = this.$route.params;
      return +defWorkflowId || 0;
    },
  },
  methods: {
    handleData() {
      if (!this.defWorkflowId) {
        this.fetchMouDetail();
      }
    },
    changeStep(step) {
      this.step = step;
    },
    setFormData(resultData) {
      this.form = {
        ...this.form,
        ...resultData,
        termOfPayment: +resultData.termOfPayment,
        mouPeriodStart: dayjs(resultData.mouPeriodStart).format(),
        mouPeriodEnd: dayjs(resultData.mouPeriodEnd).format(),
        mouPeriodStartDoc: resultData.mouPeriodStartDoc ? dayjs(resultData.mouPeriodStartDoc).format() : null,
        p2pLending: resultData.p2pLending ? resultData.p2pLending : [], // must array
        serviceTypes: resultData.serviceTypes ? resultData.serviceTypes : '',
      };
      // SET ITEMS
      this.itemsCompany = [{
        id: this.form.companyId,
        name: this.form.companyName,
      }];
      // set checkbox
      this.checkboxServiceType.isFCL = this.form.serviceTypes.split(',').includes('FCL');
      this.checkboxServiceType.isBackhauling = this.form.serviceTypes.split(',').includes('FCL_BACKHAULING');
      this.checkboxServiceType.isLCL = this.form.serviceTypes.split(',').includes('LCL');
    },
    async fetchWorkFlowData() {
      const { id } = this.$route.params;
      this.$root.$loading.show();
      try {
        const result = await this.$_services.mou.getDetailWorkflowMou(id);
        const { data } = result;
        this.setFormData(data.data);
        this.$emit('setWorkflowData', data);
        const { status } = data;
        this.isRevise = status === 'REVISE' && this.fromPath === 'approval-inbox';
      } finally {
        this.$root.$loading.hide();
      }
    },
    getSorting(pagination) {
      const { sortBy, sortDesc } = pagination;
      let sort = '';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        sort = `${sortBy[0]},${isDesc}`;
      }
      return sort;
    },
    async fetchMouDetail() {
      try {
        this.loaders.isLoadingMou = true;
        this.errors.isErrorMou = false;
        const result = await this.$_services.mou.getCooperationInformation();
        this.setFormData(result.data);
      } catch (e) {
        this.errors.isErrorMou = true;
      } finally {
        this.loaders.isLoadingMou = false;
      }
    },
    async fetchMouLocation(pagination, isDelete = null) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const filters = {
        page: page - 1,
        size,
      };
      if (isDelete !== null) filters.isDelete = isDelete;
      if (this.getSorting(pagination)) filters.sort = this.getSorting(pagination);
      try {
        this.loaders.isLoadingMouLocation = true;
        this.errors.isErrorMouLocation = false;
        const result = await this.$_services.mou.getMouLocation(filters, { mouId: this.mouId });
        this.mouLocations = result.data.contents.map((item, index) => ({
          ...item,
          index,
        }));
        this.totalDataLocation = result.data.totalData;
      } catch {
        this.errors.isErrorMouLocation = true;
      } finally {
        this.loaders.isLoadingMouLocation = false;
      }
    },
    async fetchMouTransportType(pagination, isDelete = null) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const filters = {
        page: page - 1,
        size,
      };
      if (isDelete !== null) filters.isDelete = isDelete;
      if (this.getSorting(pagination)) filters.sort = this.getSorting(pagination);
      try {
        this.loaders.isLoadingMouTransportTypes = true;
        this.errors.isErrorMouTransportTypes = false;
        const result = await this.$_services.mou.getMouTransportType(filters, { mouId: this.mouId });
        this.mouTransportTypes = result.data.contents.map((item, index) => ({
          ...item,
          index,
        }));
        this.totalDataTransportTypes = result.data.totalData;
      } catch {
        this.errors.isErrorMouTransportTypes = true;
      } finally {
        this.loaders.isLoadingMouTransportTypes = false;
      }
    },
  },
};
</script>
