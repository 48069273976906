<template>
  <v-container class="pa-0" fluid>
    <v-form ref="form">
      <v-row class="mb-4">
        <v-col class="pb-0" v-if="showBtnChangeHistory" cols="12">
          <v-btn
            color="red"
            class="pa-0 caption"
            text
            @click="showDialogChangeHistory"
          >
            {{$_strings.mou.THERE_ARE_CHANGES}}
            <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :key="reFetchData"
            :loading="isLoading"
            item-key="id"
            calculate-widths
            single-expand
            show-expand
            class="elevation-1"
            :server-items-length="totalFclRouteData"
            :options.sync="pagination"
            :headers="displayedHeaderTableFcl"
            :items="mouFclRoute"
            :item-class="itemRowBackground"
            :footer-props="{
              showCurrentPage: true,
              showFirstLastPage: true,
              'items-per-page-options': $_item_per_page,
              disablePagination: isLoading,
              disableItemsPerPage: isLoading
            }"
            :expanded.sync="expanded"
            @item-expanded="onExpanded"
          >
            <template v-slot:[`header.isAutoAssign`]="{ header }">
              <p class="text-center mb-0">
                {{header.text}}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      x-small
                      class="mb-4"
                      v-bind="attrs"
                      v-on="on"
                      color="white">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>{{$_strings.mou.A_A}}</span>
                </v-tooltip>
              </p>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
              <v-icon
                v-if="showExpandPickDrop(item)"
                :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                @click.stop="expand(!isExpanded)"
              >
                $expand
              </v-icon>
            </template>
            <template v-slot:[`item.sequenceOrderNo`]={item}>
              <v-text-field
                dense
                outlined
                disabled
                class="body-2 pt-2"
                :value="item.sequenceOrderNo ? fclMouRouteSequence.find((seqRoute) => seqRoute[item.sequenceOrderNo])[item.sequenceOrderNo] : null"
              ></v-text-field>
            </template>
            <template v-slot:[`item.origin`]={item}>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="blue--text"
                  >
                    {{item.originLocationName}}
                  </span>
                </template>
                <span>{{$_strings.order.ORIGIN}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.destination`]=items>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="blue--text"
                  >
                    {{items.item.destinationLocationName}}
                  </span>
                </template>
                <span>{{$_strings.order.DESTINATION}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.price`]={item}>
              <p>{{formatAsCurrency(item.price)}}</p>
            </template>
            <template v-slot:[`item.isAutoAssign`]={item}>
              <p class="text-center">{{item.isAutoAssign ? 'Ya' : 'Tidak'}}</p>
            </template>
            <template v-slot:[`item.transporter`]={item}>
              <p
                dense
                class="body-2"
                outlined
              >
                {{item.transportTypeName}}
              </p>
            </template>
            <template v-slot:[`item.priceMultiPick`]={item}>
              <p>{{formatAsCurrency(item.priceMultiPick)}}</p>
            </template>
            <template v-slot:[`item.priceMultiDrop`]={item}>
              <p>{{formatAsCurrency(item.priceMultiDrop)}}</p>
            </template>
            <template v-slot:[`item.isBackHauling`]={item}>
              <p>{{item.isBackHauling ? 'Ya' : 'Tidak'}}</p>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="8">
                <v-data-table
                  :headers="subHeaderTableFCL"
                  :items="item.mouRoutesMultiLoc"
                  hide-default-footer
                  :items-per-page="100"
                  class="mt-2 mb-4 elevation-1 sub_header"
                >
                  <template v-slot:[`item.origin`]={item}>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="blue--text"
                        >
                          {{item.locationName}}
                        </span>
                      </template>
                      <span>Lokasi {{ item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }} </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.pickDrop`]={item}>
                    <span>
                      {{ item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }}
                    </span>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
    <DialogChangeHistoryFcl
      :mouId="mouId"
      ref="dialogChangeHistoryFcl"
    />
  </v-container>
</template>

<script>
import DialogChangeHistoryFcl from '../../Dialog/ChangeHistoryFcl';

const formatNumber = Intl.NumberFormat('id-ID');
export default {
  name: 'FCL-page',
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    mouId: {
      type: Number,
      default: 0,
    },
    form: {
      type: Object,
      default: () => {},
    },
    mouFclRoute: {
      type: Array,
      default: () => [],
    },
    fclMouRouteSequence: {
      type: Array,
      default: () => [],
    },
    totalFclRouteData: {
      type: Number,
      default: 0,
    },
    reFetchData: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DialogChangeHistoryFcl,
  },
  data() {
    return {
      openedTableId: '',
      expanded: [],
      headerTableFCL: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'origin',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destination',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.order.ETA,
          value: 'etaDays',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'Harga (Rp)',
          value: 'price',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: 'A.A',
          value: 'isAutoAssign',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$_strings.mou.TRANSPORTER,
          value: 'transporter',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Multipick (Rp)',
          value: 'priceMultiPick',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Multidrop (Rp)',
          value: 'priceMultiDrop',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: this.$_strings.order.BACKHAULING,
          value: 'isBackHauling',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      subHeaderTableFCL: [
        {
          text: 'Nama Lokasi',
          value: 'origin',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: 'Multipick / Multidrop',
          value: 'pickDrop',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  computed: {
    showBtnChangeHistory() {
      if (!this.isPageInbox) return false;
      if (this.mouFclRoute.find((m) => m.status === 'DELETE' || m.status === 'ADD')) return true;
      return this.isShowBtnChangeHistory;
    },
    displayedHeaderTableFcl() {
      const { isMultiPick, isMultiDrop, isBackhauling } = this.form;
      return this.headerTableFCL.filter((header) => {
        if (header.value === 'priceMultiPick' && !isMultiPick) return;
        if (header.value === 'priceMultiDrop' && !isMultiDrop) return;
        if (header.value === 'isBackHauling' && !isBackhauling) return;
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        const isDelete = !this.isPageInbox ? false : null;
        this.$emit('fetchMouFclRoute', newVal, isDelete);
      },
      deep: true,
    },
    mouFclRoute: {
      handler(newVal) {
        const findLoc = newVal.find((m) => m.status === 'DELETE' || m.status === 'ADD');
        if (this.isPageInbox && !findLoc && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.checkDataChanged();
        }
      },
      deep: true,
    },
  },
  methods: {
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistoryFcl.dialog = true;
    },
    async checkDataChanged() {
      const filters = {
        page: 1,
        size: 10,
        sort: 'status,asc',
      };
      filters.isDelete = false;
      let result = await this.$_services.mou.getMouRoute(filters, { mouId: this.mouId, serviceType: 'FCL' });
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filters.isDelete = true;
        result = await this.$_services.mou.getMouRoute(filters, { mouId: this.mouId, serviceType: 'FCL' });
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox) return;
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
    showExpandPickDrop(item) {
      const condition1 = +item.priceMultiPick !== 0 || +item.priceMultiDrop !== 0;
      const condition2 = item.mouRoutesMultiLoc && item.mouRoutesMultiLoc.length;
      return condition1 || condition2;
    },
    formatAsCurrency(value) {
      return `Rp. ${formatNumber.format(value) || 0}`;
    },
    onExpanded({ item, value }) {
      if (value) {
        this.openedTableId = item.sequenceOrderNo;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  ::v-deep {
    .sub_header.theme--light.v-data-table tbody tr:nth-of-type(odd) {
      background-color: #fcf6e5;
    }
    .sub_header.theme--light.v-data-table tbody tr:nth-of-type(even) {
      background-color: #fffcf5;
    }
    .sub_header {
      .v-data-table-header {
        tr {
          .amber.accent-3 {
            background-color: #f1c231 !important;
          }
        }
      }
    }
  }
  .tooltip-icon {
    height: 10px;
    width: 10px;
  }
</style>
