<template>
  <v-container class="pa-0" fluid>
    <common-error
      v-if="isErrorGetTransportTypeLimit"
      @fetchData="isErrorGetTransportTypeLimit = false"
    />
    <v-row v-else class="mb-4">
      <v-col class="pb-0" v-if="showBtnChangeHistory" cols="12">
        <v-btn
          color="red"
          class="pa-0 caption"
          text
          @click="showDialogChangeHistory"
        >
          {{$_strings.mou.THERE_ARE_CHANGES}}
          <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          item-key="id"
          class="elevation-1"
          :loading="isLoading"
          :headers="displayedHeader"
          :items="mouTransportTypeLimit"
          :item-class="itemRowBackground"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          :options.sync="pagination"
          :server-items-length="totalData"
        >
          <template v-slot:[`item.unitLimit`]="{item}">
            <div class="d-flex">
              <v-text-field
                dense
                outlined
                v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
                class="body-2 pt-2"
                disabled
                v-model="item.unitLimit"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:[`item.limits`]="{item}">
            <div class="d-flex">
              <v-text-field
                dense
                outlined
                v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
                class="body-2 pt-2"
                v-model="item.limits"
                disabled
              ></v-text-field>
            </div>
          </template>
          <template v-slot:[`item.typeTransporter`]="{item}">
            <v-text-field
              dense
              outlined
              class="body-2 pt-2"
              :value="item.transportTypeName"
              disabled
            ></v-text-field>
          </template>
          <template v-slot:[`item.interval`]="{item}">
            <v-select
              :items="itemsInterval"
              item-text="name"
              item-value="value"
              v-model="item.interval"
              dense
              disabled
              class="body-2 pt-2"
              outlined
            ></v-select>
          </template>
          <template v-slot:[`item.price`]="{item}">
            <v-text-field
              dense
              :value="formatAsCurrency(item.price)"
              outlined
              prefix="Rp"
              disabled
              class="body-2 pt-2"
            ></v-text-field>
          </template>
          <template v-slot:[`item.action`]="{item}">
            <v-btn
              depressed
              outlined
              small
              class="mb-3"
              @click="showDialogApplyLicensePlate(item)"
            >
              {{$_strings.mou.VIEW}}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- DIALOG SET LICENSE PLATE -->
    <DialogLicensePlate
      ref="dialogLicensePlate"
      :mouId="mouId"
    />
    <DialogChangeHistoryFixedPrice
      ref="dialogChangeHistoryFixedPrice"
      :mouId="mouId"
    />
  </v-container>
</template>

<script>
import DialogLicensePlate from '../../Dialog/LicensePlate.vue';
import DialogChangeHistoryFixedPrice from '../../Dialog/ChangeHistoryFixedPrice';

export default {
  name: 'fixed-price-page',
  components: {
    DialogLicensePlate,
    DialogChangeHistoryFixedPrice,
  },
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    mouId: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (this.currentPath === 'mou') {
      this.headersTableFixedPrice.push({
        text: '',
        value: 'action',
        sortable: false,
      });
    }
  },
  data() {
    return {
      isLoading: false,
      isErrorGetTransportTypeLimit: false,
      itemsInterval: [
        {
          name: 'Bulanan',
          value: 'MONTHLY',
        },
        {
          name: 'Mingguan',
          value: 'WEEKLY',
        },
      ],
      headersTableFixedPrice: [
        {
          text: this.$_strings.mou.TRANSPORT_TYPE,
          value: 'typeTransporter',
          width: '20vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.NUMBER_OF_UNIT,
          value: 'unitLimit',
          width: '10vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.NUMBER_OF_LIMIT,
          value: 'limits',
          width: '12vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.INTERVAL,
          value: 'interval',
          width: '12vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.PRICE_INTERVAL,
          value: 'price',
          width: '15vw',
          sortable: false,
        },
      ],
      totalData: 0,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      mouTransportTypeLimit: [],
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  computed: {
    showBtnChangeHistory() {
      if (!this.isPageInbox) return false;
      if (this.mouTransportTypeLimit.find((m) => m.status === 'DELETE' || m.status === 'ADD')) return true;
      return this.isShowBtnChangeHistory;
    },
    displayedHeader() {
      return this.headersTableFixedPrice.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
    currentPath() {
      const [, , path] = this.$route.path.split('/');
      return path;
    },
  },
  watch: {
    pagination: {
      handler() {
        this.fetchMouTransportTypeLimit();
      },
      deep: true,
    },
    mouTransportTypeLimit: {
      handler(newVal) {
        const findTypeLimit = newVal.find((m) => m.status === 'DELETE' || m.status === 'ADD');
        if (this.isPageInbox && !findTypeLimit && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.checkStatusChanged();
        }
      },
      deep: true,
    },
  },
  methods: {
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistoryFixedPrice.dialog = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox) return;
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
    async checkStatusChanged() {
      const filters = {
        page: 1,
        size: 10,
        sort: 'status,asc',
      };
      filters.isDelete = false;
      let result = await this.$_services.mou.getMouTransportTypeLimit(filters, { mouId: this.mouId });
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filters.isDelete = true;
        result = await this.$_services.mou.getMouTransportTypeLimit(filters, { mouId: this.mouId });
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    async fetchMouTransportTypeLimit() {
      const { page, itemsPerPage: size } = this.pagination;
      const filters = {
        page: page - 1,
        size,
      };
      const isDelete = !this.isPageInbox ? false : null;
      if (isDelete !== null) filters.isDelete = isDelete;
      try {
        this.isLoading = true;
        this.isErrorGetTransportTypeLimit = false;
        const result = await this.$_services.mou.getMouTransportTypeLimit(filters, { mouId: this.mouId });
        this.totalData = result.data.totalData;
        this.mouTransportTypeLimit = result.data.contents.map((item, index) => ({
          ...item,
          index,
        }));
      } catch {
        this.isErrorGetTransportTypeLimit = true;
      } finally {
        this.isLoading = false;
      }
    },
    showDialogApplyLicensePlate(item) {
      this.$refs.dialogLicensePlate.dialog = true;
      this.$refs.dialogLicensePlate.item = item;
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
  },
};

</script>
