var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2 mb-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.dialog,staticClass:"elevation-1",attrs:{"item-key":"id","server-items-length":_vm.totalData,"options":_vm.pagination,"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-class":_vm.itemRowBackground,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.latLong",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(("Lat: " + (item.latitude) + " Long: " + (item.longitude)))+" ")])]}},{key:"item.description",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.length) + " x " + (item.width) + " x " + (item.height))))])]}},{key:"item.imageUrl",fn:function(ref){
        var item = ref.item;
return [(!item.imageUrl)?_c('v-icon',{staticClass:"w3",attrs:{"x-large":""}},[_vm._v(" mdi-truck-outline ")]):_c('v-img',{attrs:{"contain":"","alt":"Image Transporter","src":item.imageUrl,"height":150,"width":150,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }