var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 container_mou_location",attrs:{"fluid":""}},[_c('h3',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$_strings.mou.LOCATION_DATA))]),_c('span',[_vm._v(_vm._s(_vm.$_strings.mou.LOCATION))]),(_vm.showBtnChangeHistory)?_c('div',[_c('v-btn',{staticClass:"pa-0 caption",attrs:{"color":"red","text":""},on:{"click":_vm.showDialogChangeHistory}},[_vm._v(" "+_vm._s(_vm.$_strings.mou.THERE_ARE_CHANGES)+" "),_c('v-icon',{staticClass:"pl-4",attrs:{"size":"15"}},[_vm._v("mdi-information-outline")])],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-2 mb-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","server-items-length":_vm.totalDataLocation,"options":_vm.pagination,"loading":_vm.isLoading,"headers":_vm.displayedHeaderLocation,"items":_vm.mouLocations,"item-class":_vm.itemRowBackground,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.latLong",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(("Lat: " + (item.latitude) + " Long: " + (item.longitude)))+" ")])]}}],null,true)})],1)],1),_c('DialogChangeHistoryLocationAndTransportTypes',{ref:"dialogChangeHistory",attrs:{"mouId":_vm.mouId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }