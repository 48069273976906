<template>
  <v-container fluid class="pa-0">
    <span>{{$_strings.mou.TRANSPORT_TYPE}}</span>
    <div v-if="showBtnChangeHistory">
      <v-btn
        color="red"
        class="pa-0 caption"
        text
        @click="showDialogChangeHistory"
      >
        {{$_strings.mou.THERE_ARE_CHANGES}}
        <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
      </v-btn>
    </div>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-data-table
          :loading="isLoading"
          :headers="displayedHeaderTransporter"
          :items="mouTransportTypes"
          :server-items-length="totalDataTransportTypes"
          :options.sync="pagination"
          :item-class="itemRowBackground"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.description`]={item}>
            <span>{{`${item.length} x ${item.width} x ${item.height}`}}</span>
          </template>
          <template v-slot:[`item.imageUrl`]={item}>
            <v-icon
              v-if="!item.imageUrl"
              x-large
              class="w3"
            >
              mdi-truck-outline
            </v-icon>
            <v-img
              v-else
              contain
              alt="Image Transporter"
              :src="item.imageUrl"
              :height="150"
              :width="150"
              aspect-ratio="1"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogChangeHistoryLocationAndTransportTypes
      :mouId="mouId"
      ref="dialogChangeHistory"
    />
  </v-container>
</template>

<script>
import DialogChangeHistoryLocationAndTransportTypes from '../../Dialog/ChangeHistoryLocationAndTransportTypes/index.vue';

export default {
  name: 'detail-mou-transport-types-page',
  components: {
    DialogChangeHistoryLocationAndTransportTypes,
  },
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    mouTransportTypes: {
      type: Array,
      default: () => [],
    },
    mouId: {
      type: Number,
      default: 0,
    },
    totalDataTransportTypes: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      headersTableTransportTypes: [
        {
          text: this.$_strings.mou.TRANSPORTER_NAME,
          value: 'name',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.DESCRIPTION,
          value: 'description',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.mou.IMAGE,
          value: 'imageUrl',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      isLoadingAddTransportTypes: false,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        const isDelete = !this.isPageInbox ? false : null;
        this.$emit('fetchMouTransportType', newVal, isDelete);
      },
      deep: true,
    },
    mouTransportTypes: {
      handler(newVal) {
        const findTransport = newVal.find((m) => m.status === 'DELETE' || m.status === 'ADD');
        if (this.isPageInbox && !findTransport && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  computed: {
    displayedHeaderTransporter() {
      return this.headersTableTransportTypes.filter((header) => {
        if (this.readonly && header.value === 'action') return;
        return header;
      });
    },
    showBtnChangeHistory() {
      if (!this.isPageInbox) return false;
      if (this.mouTransportTypes.find((m) => m.status === 'DELETE' || m.status === 'ADD')) return true;
      return this.isShowBtnChangeHistory;
    },
  },
  methods: {
    async fetchData() {
      const filters = {
        page: 1,
        size: 10,
        sort: 'status,asc',
      };
      filters.isDelete = false;
      let result = await this.$_services.mou.getMouTransportType(filters, { mouId: this.mouId });
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filters.isDelete = true;
        result = await this.$_services.mou.getMouTransportType(filters, { mouId: this.mouId });
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox) return;
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistory.type = 'transport-types';
      this.$refs.dialogChangeHistory.dialog = true;
    },
  },
};

</script>
