<template>
  <v-container fluid class="pa-0">
    <v-row class="mt-2 mb-4">
      <v-col cols="12">
        <v-data-table
          :key="dialog"
          item-key="id"
          class="elevation-1"
          :loading="isLoading"
          :headers="displayedHeader"
          :items="items"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page
          }"
          :item-class="itemRowBackground"
          :options.sync="pagination"
          :server-items-length="totalData"
        >
          <template v-slot:[`item.unitLimit`]="{item}">
            <div class="d-flex">
              <v-text-field
                dense
                outlined
                v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
                class="body-2 pt-2"
                disabled
                v-model="item.unitLimit"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:[`item.limits`]="{item}">
            <div class="d-flex">
              <v-text-field
                dense
                outlined
                v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
                class="body-2 pt-2"
                v-model="item.limits"
                disabled
              ></v-text-field>
            </div>
          </template>
          <template v-slot:[`item.typeTransporter`]="{item}">
            <v-text-field
              dense
              outlined
              class="body-2 pt-2"
              :value="item.transportTypeName"
              disabled
            ></v-text-field>
          </template>
          <template v-slot:[`item.interval`]="{item}">
            <v-select
              :items="itemsInterval"
              item-text="name"
              item-value="value"
              v-model="item.interval"
              dense
              disabled
              class="body-2 pt-2"
              outlined
            ></v-select>
          </template>
          <template v-slot:[`item.price`]="{item}">
            <v-text-field
              dense
              :value="formatAsCurrency(item.price)"
              outlined
              prefix="Rp"
              disabled
              class="body-2 pt-2"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'list-fixed-price-table-history',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    fetchData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.mou.TRANSPORT_TYPE,
          value: 'typeTransporter',
          width: '20vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.NUMBER_OF_UNIT,
          value: 'unitLimit',
          width: '10vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.NUMBER_OF_LIMIT,
          value: 'limits',
          width: '12vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.INTERVAL,
          value: 'interval',
          width: '12vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.PRICE_INTERVAL,
          value: 'price',
          width: '15vw',
          sortable: false,
        },
      ],
      itemsInterval: [
        {
          name: 'Bulanan',
          value: 'MONTHLY',
        },
        {
          name: 'Mingguan',
          value: 'WEEKLY',
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      items: [],
      totalData: 0,
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.pagination = {
          page: 1,
          itemsPerPage: 10,
        };
        this.totalData = 0;
        this.items = [];
      }
    },
    pagination: {
      handler() {
        if (this.dialog) this.getData();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  methods: {
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    async getData() {
      try {
        this.isLoading = true;
        const result = await this.fetchData(this.pagination);
        this.items = result.data.contents.map((d, index) => ({
          ...d,
          index,
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    itemRowBackground(item) {
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
  },
};
</script>
