<template>
  <v-container class="pa-0" fluid>
    <v-row class="mb-0">
      <v-col class="pb-0" v-if="showBtnChangeHistory" cols="12">
        <v-btn
          color="red"
          class="pa-0 caption"
          text
          @click="showDialogChangeHistory"
        >
          {{$_strings.mou.THERE_ARE_CHANGES}}
          <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          item-key="id"
          calculate-widths
          single-expand
          show-expand
          class="elevation-1"
          :item-class="itemRowBackground"
          :loading="isLoading"
          :server-items-length="totalFclBackhaulingRouteData"
          :options.sync="pagination"
          :key="reFetchData"
          :headers="displayedHeaderBackhauling"
          :items="mouFclBackhaulingRoute"
          :expanded.sync="expanded"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
        >
          <template v-slot:[`header.isAutoAssign`]="{ header }">
            <p class="ma-0">
              {{header.text}}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-small
                    class="mb-4"
                    v-bind="attrs"
                    v-on="on"
                    color="white">
                    mdi-information
                  </v-icon>
                </template>
                <span>{{$_strings.mou.A_A}}</span>
              </v-tooltip>
            </p>
          </template>
          <template v-slot:[`item.sequenceOrderNo`]={item,index}>
            <v-text-field
              dense
              outlined
              disabled
              class="body-2 pt-2"
              :value="item.sequenceOrderNo ? getSequence(item.sequenceOrderNo, index) : ''"
            ></v-text-field>
          </template>
          <template v-slot:[`item.origin`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.originLocationName}}
                </span>
              </template>
              <span>{{$_strings.order.ORIGIN}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.destination`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.destinationLocationName }}
                </span>
              </template>
              <span>{{$_strings.order.DESTINATION}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.price`]={item}>
            <p>{{formatAsCurrency(item.price)}}</p>
          </template>
          <template v-slot:[`item.isAutoAssign`]={item}>
            <p>{{item.isAutoAssign ? 'Ya' : 'Tidak'}}</p>
          </template>
          <template v-slot:[`item.transportTypeId`]={item}>
            <p>{{item.transportTypeName}}</p>
          </template>
          <template v-slot:[`item.priceMultiPick`]={item}>
            <p>{{formatAsCurrency(item.priceMultiPick)}}</p>
          </template>
          <template v-slot:[`item.priceMultiDrop`]={item}>
            <p>{{formatAsCurrency(item.priceMultiDrop)}}</p>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
            <v-icon
              v-if="showExpandPickDrop(item)"
              :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
              @click.stop="expand(!isExpanded)"
            >
              $expand
            </v-icon>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="8">
              <v-data-table
                :headers="subHeaderTable"
                :items="item.mouRoutesMultiLoc"
                :items-per-page="100"
                hide-default-footer
                class="mt-2 mb-4 elevation-1 sub_header"
              >
                <template v-slot:[`item.origin`]={item}>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="blue--text"
                      >
                        {{item.locationName}}
                      </span>
                    </template>
                    <span>Lokasi {{ item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }} </span>
                  </v-tooltip>
                </template>
                <!-- TYPE LOCATION -->
                <template v-slot:[`item.pickDrop`]={item}>
                  <span>
                    {{ item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }}
                  </span>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogChangeHistoryFclBackhauling
      :mouId="mouId"
      ref="dialogChangeHistory"
    />
  </v-container>
</template>

<script>
import DialogChangeHistoryFclBackhauling from '../../Dialog/ChangeHistoryFclBackhauling';

export default {
  props: {
    mouFclBackhaulingRoute: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
    fclMouRouteSequence: {
      type: Array,
      default: () => [],
    },
    reFetchData: {
      type: Number,
      default: 0,
    },
    totalFclBackhaulingRouteData: {
      type: Number,
      default: 0,
    },
    mouId: {
      type: Number,
      default: 0,
    },
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    DialogChangeHistoryFclBackhauling,
  },
  watch: {
    pagination: {
      handler(newVal) {
        const isDelete = !this.isPageInbox ? false : null;
        this.$emit('fetchMouFclBackhaulingRoute', newVal, isDelete);
      },
      deep: true,
    },
    mouFclBackhaulingRoute: {
      handler(newVal) {
        const findLoc = newVal.find((m) => m.status === 'DELETE' || m.status === 'ADD');
        if (this.isPageInbox && !findLoc && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.checkDataChanged();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      headerTableBackhauling: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'origin',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destination',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Harga (Rp)',
          value: 'price',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'A.A',
          value: 'isAutoAssign',
          width: '100px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.mou.TRANSPORTER,
          value: 'transportTypeId',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'Multipick (Rp)',
          value: 'priceMultiPick',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'Multidrop (Rp)',
          value: 'priceMultiDrop',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.order.BACKHAULING,
          value: 'backHaulingType',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      subHeaderTable: [
        {
          text: 'Nama Lokasi',
          value: 'origin',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: 'Multipick / Multidrop',
          value: 'pickDrop',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
      expanded: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  computed: {
    showBtnChangeHistory() {
      if (!this.isPageInbox) return false;
      if (this.mouFclBackhaulingRoute.find((m) => m.status === 'DELETE' || m.status === 'ADD')) return true;
      return this.isShowBtnChangeHistory;
    },
    displayedHeaderBackhauling() {
      const { isMultiPick, isMultiDrop } = this.form;
      return this.headerTableBackhauling.filter((header) => {
        if (header.value === 'priceMultiPick' && !isMultiPick) return;
        if (header.value === 'priceMultiDrop' && !isMultiDrop) return;
        return header;
      });
    },
  },
  methods: {
    async checkDataChanged() {
      const filters = {
        page: 1,
        size: 10,
        sort: 'status,asc',
      };
      filters.isDelete = false;
      let result = await this.$_services.mou.getMouRoute(filters, { mouId: this.mouId, serviceType: 'FCL_BACKHAULING' });
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filters.isDelete = true;
        result = await this.$_services.mou.getMouRoute(filters, { mouId: this.mouId, serviceType: 'FCL_BACKHAULING' });
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox) return;
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistory.dialog = true;
    },
    getSequence(sequence, index) {
      const findSeqFcl = this.fclMouRouteSequence.find((seqRoute) => seqRoute[sequence]);
      let subSequence = 0;
      if (this.mouFclBackhaulingRoute[index - 1]) {
        if (this.mouFclBackhaulingRoute[index - 1].sequenceOrderNo === sequence) {
          subSequence = 1;
        }
      }
      if (this.mouFclBackhaulingRoute[index + 1]) {
        if (this.mouFclBackhaulingRoute[index + 1].sequenceOrderNo === sequence) {
          subSequence = 0;
        }
      }
      if (findSeqFcl) {
        return `${findSeqFcl[sequence]}.${subSequence}`;
      }
      return sequence;
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return `Rp. ${formatNumber.format(value) || 0}`;
    },
    showExpandPickDrop(item) {
      const condition1 = +item.priceMultiPick !== 0 || +item.priceMultiDrop !== 0;
      const condition2 = item.mouRoutesMultiLoc && item.mouRoutesMultiLoc.length;
      return condition1 || condition2;
    },
  },
};

</script>

<style lang="scss" scoped>
  ::v-deep {
    .sub_header.theme--light.v-data-table tbody tr:nth-of-type(odd) {
      background-color: #fcf6e5;
    }
    .sub_header.theme--light.v-data-table tbody tr:nth-of-type(even) {
      background-color: #fffcf5;
    }
    .sub_header {
      .v-data-table-header {
        tr {
          .amber.accent-3 {
            background-color: #f1c231 !important;
          }
        }
      }
    }
  }
  .tooltip-icon {
    height: 10px;
    width: 10px;
  }
</style>
