var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"mb-4"},[(_vm.showBtnChangeHistory)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"pa-0 caption",attrs:{"color":"red","text":""},on:{"click":_vm.showDialogChangeHistory}},[_vm._v(" "+_vm._s(_vm.$_strings.mou.THERE_ARE_CHANGES)+" "),_c('v-icon',{staticClass:"pl-4",attrs:{"size":"15"}},[_vm._v("mdi-information-outline")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.reFetchData,staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"item-key":"id","calculate-widths":"","single-expand":"","show-expand":"","server-items-length":_vm.totalFclRouteData,"options":_vm.pagination,"headers":_vm.displayedHeaderTableFcl,"items":_vm.mouFclRoute,"item-class":_vm.itemRowBackground,"footer-props":{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': _vm.$_item_per_page,
            disablePagination: _vm.isLoading,
            disableItemsPerPage: _vm.isLoading
          },"expanded":_vm.expanded},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.onExpanded},scopedSlots:_vm._u([{key:"header.isAutoAssign",fn:function(ref){
          var header = ref.header;
return [_c('p',{staticClass:"text-center mb-0"},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"x-small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.mou.A_A))])])],1)]}},{key:"item.data-table-expand",fn:function(ref){
          var item = ref.item;
          var isExpanded = ref.isExpanded;
          var expand = ref.expand;
return [(_vm.showExpandPickDrop(item))?_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v(" $expand ")]):_vm._e()]}},{key:"item.sequenceOrderNo",fn:function(ref){
          var item = ref.item;
return [_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":"","value":item.sequenceOrderNo ? _vm.fclMouRouteSequence.find(function (seqRoute) { return seqRoute[item.sequenceOrderNo]; })[item.sequenceOrderNo] : null}})]}},{key:"item.origin",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.originLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.ORIGIN))])])]}},{key:"item.destination",fn:function(items){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(items.item.destinationLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.DESTINATION))])])]}},{key:"item.price",fn:function(ref){
          var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.formatAsCurrency(item.price)))])]}},{key:"item.isAutoAssign",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.isAutoAssign ? 'Ya' : 'Tidak'))])]}},{key:"item.transporter",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"body-2",attrs:{"dense":"","outlined":""}},[_vm._v(" "+_vm._s(item.transportTypeName)+" ")])]}},{key:"item.priceMultiPick",fn:function(ref){
          var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.formatAsCurrency(item.priceMultiPick)))])]}},{key:"item.priceMultiDrop",fn:function(ref){
          var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.formatAsCurrency(item.priceMultiDrop)))])]}},{key:"item.isBackHauling",fn:function(ref){
          var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.isBackHauling ? 'Ya' : 'Tidak'))])]}},{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return [_c('td',{attrs:{"colspan":8}},[_c('v-data-table',{staticClass:"mt-2 mb-4 elevation-1 sub_header",attrs:{"headers":_vm.subHeaderTableFCL,"items":item.mouRoutesMultiLoc,"hide-default-footer":"","items-per-page":100},scopedSlots:_vm._u([{key:"item.origin",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.locationName)+" ")])]}}],null,true)},[_c('span',[_vm._v("Lokasi "+_vm._s(item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop')+" ")])])]}},{key:"item.pickDrop",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop')+" ")])]}}],null,true)})],1)]}}],null,true)})],1)],1)],1),_c('DialogChangeHistoryFcl',{ref:"dialogChangeHistoryFcl",attrs:{"mouId":_vm.mouId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }