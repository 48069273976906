<template>
  <v-container fluid class="pa-0">
    <v-row class="mt-2 mb-4">
      <v-col cols="12">
        <v-data-table
          :key="dialog"
          :loading="isLoading"
          item-key="id"
          calculate-widths
          single-expand
          show-expand
          class="elevation-1"
          :server-items-length="totalData"
          :options.sync="pagination"
          :headers="headerTableFCLBackhauling"
          :items="items"
          :item-class="itemRowBackground"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
          :expanded.sync="expanded"
        >
          <template v-slot:[`header.isAutoAssign`]="{ header }">
            <p class="ma-0">
              {{header.text}}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-small
                    class="mb-4"
                    v-bind="attrs"
                    v-on="on"
                    color="white">
                    mdi-information
                  </v-icon>
                </template>
                <span>{{$_strings.mou.A_A}}</span>
              </v-tooltip>
            </p>
          </template>
          <template v-slot:[`item.sequenceOrderNo`]={item,index}>
            <v-text-field
              dense
              outlined
              disabled
              class="body-2 pt-2"
              :value="item.sequenceOrderNo ? getSequence(item.sequenceOrderNo, index) : ''"
            ></v-text-field>
          </template>
          <template v-slot:[`item.origin`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.originLocationName}}
                </span>
              </template>
              <span>{{$_strings.order.ORIGIN}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.destination`]={item}>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.destinationLocationName }}
                </span>
              </template>
              <span>{{$_strings.order.DESTINATION}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.price`]={item}>
            <p>{{formatAsCurrency(item.price)}}</p>
          </template>
          <template v-slot:[`item.isAutoAssign`]={item}>
            <p>{{item.isAutoAssign ? 'Ya' : 'Tidak'}}</p>
          </template>
          <template v-slot:[`item.transportTypeId`]={item}>
            <p>{{item.transportTypeName}}</p>
          </template>
          <template v-slot:[`item.priceMultiPick`]={item}>
            <p>{{formatAsCurrency(item.priceMultiPick)}}</p>
          </template>
          <template v-slot:[`item.priceMultiDrop`]={item}>
            <p>{{formatAsCurrency(item.priceMultiDrop)}}</p>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
            <v-icon
              v-if="showExpandPickDrop(item)"
              :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
              @click.stop="expand(!isExpanded)"
            >
              $expand
            </v-icon>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="8">
              <v-data-table
                :headers="subHeaderTable"
                :items="item.mouRoutesMultiLoc"
                :items-per-page="100"
                hide-default-footer
                class="mt-2 mb-4 elevation-1 sub_header"
              >
                <template v-slot:[`item.origin`]={item}>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="blue--text"
                      >
                        {{item.locationName}}
                      </span>
                    </template>
                    <span>Lokasi {{ item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }} </span>
                  </v-tooltip>
                </template>
                <!-- TYPE LOCATION -->
                <template v-slot:[`item.pickDrop`]={item}>
                  <span>
                    {{ item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }}
                  </span>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'list-fcl-backhauling-table-history',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    fetchData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headerTableFCLBackhauling: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'origin',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destination',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Harga (Rp)',
          value: 'price',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'A.A',
          value: 'isAutoAssign',
          width: '100px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.mou.TRANSPORTER,
          value: 'transportTypeId',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'Multipick (Rp)',
          value: 'priceMultiPick',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'Multidrop (Rp)',
          value: 'priceMultiDrop',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.order.BACKHAULING,
          value: 'backHaulingType',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      subHeaderTable: [
        {
          text: 'Nama Lokasi',
          value: 'origin',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: 'Multipick / Multidrop',
          value: 'pickDrop',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      expanded: [],
      items: [],
      totalData: 0,
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.pagination = {
          page: 1,
          itemsPerPage: 10,
        };
        this.totalData = 0;
        this.items = [];
      }
    },
    pagination: {
      handler() {
        if (this.dialog) this.getData();
      },
      deep: true,
    },
  },
  methods: {
    showExpandPickDrop(item) {
      const condition1 = +item.priceMultiPick !== 0 || +item.priceMultiDrop !== 0;
      const condition2 = item.mouRoutesMultiLoc && item.mouRoutesMultiLoc.length;
      return condition1 || condition2;
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return `Rp. ${formatNumber.format(value) || 0}`;
    },
    async getData() {
      try {
        this.isLoading = true;
        const result = await this.fetchData(this.pagination);
        this.items = result.data.contents.map((d, index) => ({
          ...d,
          index,
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    itemRowBackground(item) {
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
  },
};
</script>
