<template>
  <v-container fluid class="pa-0">
    <v-row class="mt-2 mb-4">
      <v-col cols="12">
        <v-data-table
          :key="dialog"
          item-key="id"
          class="elevation-1"
          :server-items-length="totalData"
          :options.sync="pagination"
          :loading="isLoading"
          :headers="displayedHeader"
          :items="items"
          :item-class="itemRowBackground"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
        >
          <template v-slot:[`item.latLong`]={item}>
            <span>
              {{`Lat: ${item.latitude} Long: ${item.longitude}`}}
            </span>
          </template>
          <template v-slot:[`item.description`]={item}>
            <span>{{`${item.length} x ${item.width} x ${item.height}`}}</span>
          </template>
          <template v-slot:[`item.imageUrl`]={item}>
            <v-icon
              v-if="!item.imageUrl"
              x-large
              class="w3"
            >
              mdi-truck-outline
            </v-icon>
            <v-img
              v-else
              contain
              alt="Image Transporter"
              :src="item.imageUrl"
              :height="150"
              :width="150"
              aspect-ratio="1"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'list-data-table-history',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    fetchData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headersTableLocation: [
        {
          text: this.$_strings.mou.LOCATION_NAME,
          value: 'locationName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: `${this.$_strings.mou.CITY}/${this.$_strings.mou.DISTRICT}`,
          value: 'locationCityName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.ADDRESS,
          value: 'address',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.GOOGLE_MAPS,
          value: 'latLong',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      headersTableTransportTypes: [
        {
          text: this.$_strings.mou.TRANSPORTER_NAME,
          value: 'name',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.DESCRIPTION,
          value: 'description',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.mou.IMAGE,
          value: 'imageUrl',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      items: [],
      totalData: 0,
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.pagination = {
          page: 1,
          itemsPerPage: 10,
        };
        this.totalData = 0;
        this.items = [];
      }
    },
    pagination: {
      handler() {
        if (this.dialog) this.getData();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      if (this.type === 'location') return this.headersTableLocation;
      return this.headersTableTransportTypes;
    },
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const result = await this.fetchData(this.pagination);
        this.items = result.data.contents.map((d, index) => ({
          ...d,
          index,
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    itemRowBackground(item) {
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
  },
};

</script>
