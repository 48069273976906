var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2 mb-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.dialog,staticClass:"elevation-1",attrs:{"item-key":"id","loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page
        },"item-class":_vm.itemRowBackground,"options":_vm.pagination,"server-items-length":_vm.totalData},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.unitLimit",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'integer', allowMinus: false, rightAlign: false}),expression:"{'alias': 'integer', allowMinus: false, rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":""},model:{value:(item.unitLimit),callback:function ($$v) {_vm.$set(item, "unitLimit", $$v)},expression:"item.unitLimit"}})],1)]}},{key:"item.limits",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'integer', allowMinus: false, rightAlign: false}),expression:"{'alias': 'integer', allowMinus: false, rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":""},model:{value:(item.limits),callback:function ($$v) {_vm.$set(item, "limits", $$v)},expression:"item.limits"}})],1)]}},{key:"item.typeTransporter",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","value":item.transportTypeName,"disabled":""}})]}},{key:"item.interval",fn:function(ref){
        var item = ref.item;
return [_c('v-select',{staticClass:"body-2 pt-2",attrs:{"items":_vm.itemsInterval,"item-text":"name","item-value":"value","dense":"","disabled":"","outlined":""},model:{value:(item.interval),callback:function ($$v) {_vm.$set(item, "interval", $$v)},expression:"item.interval"}})]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","value":_vm.formatAsCurrency(item.price),"outlined":"","prefix":"Rp","disabled":""}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }