<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1200"
    scrollable
  >
    <v-card class="pa-2">
      <v-card-title class="caption">
        <h2>{{$_strings.mou.DATA_HISTORY}}</h2>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <h3>{{$_strings.mou.PREVIOUS_DATA}}</h3>
            <ListTableData
              :form="form"
              :dialog="dialog"
              :fetchData="fetchPreviosData"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>{{$_strings.mou.LATEST_DATA}}</h3>
            <ListTableData
              :form="form"
              :dialog="dialog"
              :fetchData="fetchLatestData"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="d-flex justify-center"
      >
        <v-row justify="end">
          <v-col cols="auto">
            <v-btn
              color="primary"
              small
              class="white--text mr-4"
              @click="dialog = false"
            >
              Ok
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  skipEmptyStringObject,
} from '@/helper/commonHelpers';
import ListTableData from './ListTableData.vue';

export default {
  components: {
    ListTableData,
  },
  props: {
    form: {
      typeof: Object,
      default: () => {},
    },
    mouId: {
      typeof: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
      type: '',
    };
  },
  methods: {
    service(status) {
      switch (status) {
        case 'prev': return this.$_services.mou.getMouRoute;
        case 'next': return this.$_services.mou.getMouRoute;
        default: return {};
      }
    },
    fetchPreviosData(pagination) {
      const {
        page,
        itemsPerPage: size,
      } = pagination;
      const filters = skipEmptyStringObject({
        page: page - 1,
        size,
        isDelete: true,
      });
      const serviceType = 'LCL';
      return this.$_services.mou.getOldMouRoute(this.mouId, serviceType, filters);
    },
    fetchLatestData(pagination) {
      const {
        page,
        itemsPerPage: size,
      } = pagination;
      const filters = skipEmptyStringObject({
        page: page - 1,
        size,
        isDelete: false,
      });
      const serviceType = 'LCL';
      const status = 'next';
      return this.service(status)(filters, { mouId: this.mouId, serviceType });
    },
  },
};
</script>
