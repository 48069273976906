var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-0"},[(_vm.showBtnChangeHistory)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"pa-0 caption",attrs:{"color":"red","text":""},on:{"click":_vm.showDialogChangeHistory}},[_vm._v(" "+_vm._s(_vm.$_strings.mou.THERE_ARE_CHANGES)+" "),_c('v-icon',{staticClass:"pl-4",attrs:{"size":"15"}},[_vm._v("mdi-information-outline")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.reFetchData,staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeaderLCL,"items":_vm.mouLclRoute,"item-class":_vm.itemRowBackground,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        },"item-key":String(Math.random()),"server-items-length":_vm.totalLclRouteData,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header.priceKilo",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(header.text))]),_c('span',[_c('v-checkbox',{staticClass:"d-inline",attrs:{"color":"white","hide-details":"","disabled":""},model:{value:(_vm.form.isPriceKilo),callback:function ($$v) {_vm.$set(_vm.form, "isPriceKilo", $$v)},expression:"form.isPriceKilo"}})],1)])]}},{key:"header.priceVolume",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(header.text))]),_c('span',[_c('v-checkbox',{staticClass:"d-inline",attrs:{"color":"white","hide-details":"","disabled":""},model:{value:(_vm.form.isPriceVolume),callback:function ($$v) {_vm.$set(_vm.form, "isPriceVolume", $$v)},expression:"form.isPriceVolume"}})],1)])]}},{key:"header.priceCarton",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(header.text))]),_c('span',[_c('v-checkbox',{staticClass:"d-inline",attrs:{"color":"white","hide-details":"","disabled":""},model:{value:(_vm.form.isPriceCarton),callback:function ($$v) {_vm.$set(_vm.form, "isPriceCarton", $$v)},expression:"form.isPriceCarton"}})],1)])]}},{key:"item.sequenceOrderNo",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":"","value":item.sequenceOrderNo ? _vm.lclMouRouteSequence.find(function (seqRoute) { return seqRoute[item.sequenceOrderNo]; })[item.sequenceOrderNo]: null}})]}},{key:"item.origin",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.originLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.ORIGIN))])])]}},{key:"item.destination",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.destinationLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.DESTINATION))])])]}},{key:"item.priceKilo",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatAsCurrency(item.priceKilo)))])]}},{key:"item.priceVolume",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatAsCurrency(item.priceVolume)))])]}},{key:"item.priceCarton",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.formatAsCurrency(item.priceCarton)))])]}}],null,true)})],1)],1),_c('DialogChangeHistoryLcl',{ref:"dialogChangeHistoryLcl",attrs:{"form":_vm.form,"mouId":_vm.mouId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }